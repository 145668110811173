export enum RemoteConfig {
    cntOtp='cntOtp',
    uriPostRegisterCred='uriPostRegisterCred',
    uriPostReqRegisterCred='uriPostReqRegisterCred',
    uriPostLoginCred='uriPostLoginCred',
    uriPostReqLoginCred='uriPostReqLoginCred',
    uriPutActivateBiometric='uriPutActivateBiometric',
    uriPutDeactivateBiometric='uriPutDeactivateBiometric',
    uriGetSetting='uriGetSetting',
    uriPostLogin='uriPostLogin',
    uriPostRefreshToken='uriPostRefreshToken',
    uriPutDeviceToken='uriPutDeviceToken',
    uriPostRegister='uriPostRegister',
    uriPostReqOTPRegUser='uriPostReqOTPRegUser',
    uriPostValidOTPRegUser='uriPostValidOTPRegUser',
    uriValidateUser='uriValidateUser',
    uriGetPostUsers='uriGetPostUsers',
    uriGetRelatedUserPH='uriGetRelatedUserPH',
    uriPostSearchUsers='uriPostSearchUsers',
    uriGetPutDelUserDetail='uriGetPutDelUserDetail',
    uriPutDeactivateUser='uriPutDeactivateUser',
    uriGetRoles='uriGetRoles',
    uriGetClientsByPolRel='uriGetClientsByPolRel',
    uriGetRelsByPolNum='uriGetRelsByPolNum',
    uriPostInfoBenefDocs='uriPostInfoBenefDocs',
    uriPostChangeBenef='uriPostChangeBenef',
    uriPostUploadBenefDoc='uriPostUploadBenefDoc',
    uriGetIsBenefTrxAllowed='uriGetIsBenefTrxAllowed',
    uriGetTutorialVideos='uriGetTutorialVideos',
    uriGetTutorialVideoById='uriGetTutorialVideoById',
    uriPostTutorialVideo='uriGetTutorialVideos',
    uriDeleteTutorialVideo='uriGetTutorialVideoById',
    uriPutTutorialVideo='uriGetTutorialVideoById',
    uriGetVideoHeaders='uriGetVideoHeaders',
    uriGetVideoHeadersById='uriGetVideoHeadersById',
    uriGetVideoYoutubeDetail='uriGetVideoYoutubeDetail',
    uriPostQontakInfo='uriPostQontakInfo',

    // message
    uriGetMessages='uriGetMessages',
    uriGetMsgDetail='uriGetMsgDetail',
    uriGetBroadcast='uriGetBroadcast',
    uriPutOpenMsg='uriPutOpenMsg',
    uriPostSendMsg='uriPostSendMsg',
    uriPostSendBroadcast='uriPostSendBroadcast',
    uriGetCheckNewNotif='uriGetCheckNewNotif',
    // firebase
    uriPutSubTopic='uriPutSubTopic',
    uriPutUnsubTopic='uriPutUnsubTopic',

    uriPutChangePass='uriPutChangePass',
    uriPutLogout='uriPutLogout',
    uriPutDisclaimer='uriPutDisclaimer',

    uriPutResetPass='uriPutResetPass',
    uriGetResetPass='uriGetResetPass',
    uriPostResetPassReq='uriPostResetPassReq',
    uriUserProfile='uriUserProfile',
    uriChangeUserPP='uriChangeUserPP',
    uriPostProfileValidate='uriPostProfileValidate',
    uriPutAltProfile='uriPutAltProfile',

    // policy
    uriGetDebtCois='uriGetDebtCois',
    uriGetSummaryCoi='uriGetSummaryCoi',
    uriGetPolicies='uriGetPolicies',
    uriGetEPaymentPolicies='uriGetEPaymentPolicies',
    uriGetPolicyData='uriGetPolicyData',
    uriAdditionalInsured='uriAdditionalInsured',
    uriPolicyProduct='uriPolicyProduct',
    uriPolicyAgent='uriPolicyAgent',
    uriPolicyProductRider='uriPolicyProductRider',
    uriGetCountries='uriGetCountries',
    uriGetAddressTypes='uriGetAddressTypes',
    uriPutAltAddress='uriPutAltAddress',
    uriGetAgentByPolicyNums='uriGetAgentByPolicyNums',
    uriGetKlikDokterUri='uriGetKlikDokterUri',
    uriGetKlikDokterPolicies='uriGetKlikDokterPolicies',
    uriPostKlikDokterAgree='uriPostKlikDokterAgree',
    uriGetKlikDokterTerm='uriGetKlikDokterTerm',
    uriGetKlikDokterCheckTerm='uriGetKlikDokterCheckTerm',
    uriGetECards='uriGetECards',

    // transaction
    uriPostSearchTrxStatements='uriPostSearchTrxStatements',
    uriPostSearchTrx='uriPostSearchTrx',
    uriDownloadStatementForms='uriDownloadStatementForms',
    uriSendStatement='uriSendStatement',
    uriDownloadStatement="uriDownloadStatement",

    // claim
    uriGetClaims='uriGetClaims',
    uriGetClaimDetail='uriGetClaimDetail',
    uriGetClaimUsers='uriGetClaimUsers',
    uriGetClaimBenefits='uriGetClaimBenefits',
    uriGetHospCountries='uriGetHospCountries',
    uriGetHospProvinces='uriGetHospProvinces',
    uriGetHospCities='uriGetHospCities',
    uriGetHospCategories='uriGetHospCategories',
    uriGetHospitals='uriGetHospitals',
    uriGetBenefitDocuments='uriGetBenefitDocuments',
    uriPutDocumentComplete='uriPutDocumentComplete',
    uriGetBenefitPendingDocuments='uriGetBenefitPendingDocuments',
    uriPostClaimOtp='uriPostClaimOtp',
    uriPostValidateClaimOtp='uriPostValidateClaimOtp',
    uriPostSubmitClaim='uriPostSubmitClaim',
    uriPostValidateClaim='uriPostValidateClaim',
    uriPostSubmitDocumentClaim='uriPostSubmitDocumentClaim',
    uriPostReqFollowUpOtp='uriPostReqFollowUpOtp',
    uriPostValidateFollowUpOtp='uriPostValidateFollowUpOtp',
    uriPostFollowupDoc='uriPostFollowupDoc',
    uriPutFollowupDocComplete='uriPutFollowupDocComplete',
    uriPostReqOtp='uriPostReqOtp',
    uriPostValidateOtp='uriPostValidateOtp',
    uriGetClaimLetters='uriGetClaimLetters',
    uriGetClaimLettersDownload='uriGetClaimLettersDownload',
    uriGetClaimPayment='uriGetClaimPayment',
    uriGetClaimPaymentDownload='uriGetClaimPaymentDownload',

    // send
    uriSendEPolicy='uriSendEPolicy',
    // download
    uriDownloadEPolicy='uriDownloadEPolicy',

    // repricing
    uriGetRepricingByURI='uriGetRepricingByURI',
    uriGetRepriceConfirmation="uriGetRepriceConfirmation",
    uriGetRepricingLinkValid="uriGetRepricingLinkValid",
    uriGetRepricingByEncTrx="uriGetRepricingByEncTrx",
    uriGetRepricingByPolicyPH="uriGetRepricingByPolicyPH",
    uriPutRepricing="uriPutRepricing",
    uriPostLoginReprice="uriPostLoginReprice",
    uriGetRepriceHeaderByLinkUri="uriGetRepriceHeaderByLinkUri",
    // repricing cbp
    uriGetRepriceConversionEligibility="uriGetRepriceConversionEligibility",
    uriGetRepriceConversionPolicy="uriGetRepriceConversionPolicy",
    uriGetRepriceConversionClients="uriGetRepriceConversionClients",
    uriGetRepriceConversionClientsSimulation="uriGetRepriceConversionClientsSimulation",
    uriPostRepriceConversionClientsConfirmation="uriPostRepriceConversionClientsConfirmation",
    uriGetRepriceConversionLetters="uriGetRepriceConversionLetters",
    // repricing asset url
    uriRepricingCBPVideo="uriRepricingCBPVideo",
    uriCBPVideo="uriCBPVideo",
    uriRepricingCBPVideoThumbnail="uriRepricingCBPVideoThumbnail",
    uriCBPVideoThumbnail="uriCBPVideoThumbnail",
    uriDownloadCBPProductDetailAge="uriDownloadCBPProductDetailAge",
    uriDownloadCBPProductDetailBan="uriDownloadCBPProductDetailBan",
    uriConversionFooter="uriConversionFooter",

    // products
    uriGetPremiumCalculation="uriGetPremiumCalculation",
    uriGetProductPlanSummary="uriGetProductPlanSummary",

    // payment
    uriPaymentInformation='uriPaymentInformation',
    uriGetPaymentMethods='uriGetPaymentMethods',
    uriGetPaymentFreqs='uriGetPaymentFreqs',
    uriGetCalcPayFreq='uriGetCalcPayFreq',
    uriPutPaymentMethods='uriPutPaymentMethods',
    uriBankInformation='uriBankInformation',
    uriBankWithdrawInfo='uriBankWithdrawInfo',
    uriPaymentHistory='uriPaymentHistory',
    uriDownloadPaymentHistory='uriDownloadPaymentHistory',
    uriGetPremiumInvoices='uriGetPremiumInvoices',
    uriPostReqPayment='uriPostReqPayment',
    uriPostReqRecurrCC='uriPostReqRecurrCC',
    uriPutCancelPayment='uriPutCancelPayment',
    uriGetBillings='uriGetBillings',
    uriGetPremiumHolidays='uriGetPremiumHolidays',
    uriPostPaymentFreqs='uriPostPaymentFreqs',
    uriGetCreditCardBanks='uriGetCreditCardBanks',
    uriGetAutodebetBanks='uriGetAutodebetBanks',
    // change payment
    uriGetAutodebetBanksOnline='uriGetAutodebetBanksOnline',
    uriGetAutodebetBankRek='uriGetAutodebetBankRek',
    uriGetPaymentMethodsDocs='uriGetPaymentMethodsDocs',
    uriPostPaymentMethodsDocs='uriPostPaymentMethodsDocs',
    uriPostChangePaymentAutodebet='uriPostChangePaymentAutodebet',
    uriPostChangePaymentCC='uriPostChangePaymentCC',
    uriGetValidateChangePayMethod='uriGetValidateChangePayMethod',
    uriPostGenerateESKPR='uriPostGenerateESKPR',
    uriPutCancelChangePaymentCC='uriPutCancelChangePaymentCC',

    // resource
    uriGetBanners='uriGetBanners',
    uriGetActiveBanners='uriGetActiveBanners',
    uriDeleteBanners='uriDeleteBanners',
    uriPostBanners='uriPostBanners',
    uriPutBanners='uriPutBanners',

    // form
    uriGetForms='uriGetForms',
    uriDownloadForms='uriDownloadForms',


    // files
    uriGetFilesByFolderName='uriGetFilesByFolderName',
    uriDownloadFiles='uriDownloadFiles',
    uriDownloadFileByFilter='uriDownloadFileByFilter',
    uriDownloadFileByName='uriDownloadFileByName',
    uriDownloadOTLFile='uriDownloadOTLFile',

    uriGetInvestments='uriGetInvestments',
    uriGetInvestmentTypes='uriGetInvestmentTypes',
    uriGetSWExcFundTypes='uriGetSWExcFundTypes',
    uriGetUnitPrice='uriGetUnitPrice',
    uriGetLastUnitPrice='uriGetLastUnitPrice',
    uriGetSwitching='uriGetSwitching',
    uriPostSwitching='uriPostSwitching',
    uriGetRedirection='uriGetRedirection',
    uriPostRedirection='uriPostRedirection',
    uriGetValidateSTU='uriGetValidateSTU',
    uriPostValidateAmtSTU='uriPostValidateAmtSTU',
    uriPostSTU='uriPostSTU',

    uriGetFAQPage='uriGetFAQPage',
    uriGetFilterPage='uriGetFilterPage',
    uriGetPostPages='uriGetPostPages',
    uriGetPutDeletePage='uriGetPutDeletePage',
    uriGetFiles='uriGetFiles',
    uriCopyFile='uriCopyFile',
    uriMoveFile='uriMoveFile',
    uriMoveFolder='uriMoveFolder',
    uriUploadFiles='uriUploadFiles',
    uriUploadFolders='uriUploadFolders',
    uriRenameFolders='uriRenameFolders',
    uriDeleteFiles='uriDeleteFiles',
    uriDeleteFolders='uriDeleteFolders',
    uriGetNearHospital='uriGetNearHospital',
    uriGetHealthProviders='uriGetHealthProviders',
    uriPostUploadHospital='uriPostUploadHospital',
    uriGetValidateEmail = 'uriGetValidateEmail',
    uriGetSourceFunds='uriGetSourceFunds',
    uriGetOLPayMethods='uriGetOLPayMethods',
    uriGetOLPayInsts='uriGetOLPayInsts',
    uriGetTypeIds='uriGetTypeIds',
    uriGetGenders='uriGetGenders',
    uriGetBeneficiaryRules='uriGetBeneficiaryRules',
    uriGetReinstatementValid='uriGetReinstatementValid',
    uriGetReinstatementType='uriGetReinstatementType',
    uriGetReinstatementInfo='uriGetReinstatementInfo',
    uriGetReinstatementReqDocs='uriGetReinstatementReqDocs',
    uriPostUploadReinstatementDocs='uriPostUploadReinstatementDocs',
    uriPostAddReinstatement='uriPostAddReinstatement',
    uriGetOTLData='uriGetOTLData',
    uriGetOTLDecrypt='uriGetOTLDecrypt',
    uriGetOTLPage='uriGetOTLPage',
    uriPostOTLLogin='uriPostOTLLogin',
    uriPostValidateClientPH='uriPostValidateClientPH',
    uriGetLovByCode='uriGetLovByCode',
    uriGetLovsByGroupCode='uriGetLovsByGroupCode',
    uriPostLogPage='uriPostLogPage',
    uriPostLogLogin='uriPostLogLogin',
    uriGetDynamicContents='uriGetDynamicContents',
    isCheckSMSHash='isCheckSMSHash',
    googleSiteKey='googleSiteKey',
    checkVerCode='checkVerCode',
    timeoutCode='timeoutCode',
    WACallCenter='WACallCenter',
    WAPredefinedMsg='WAPredefinedMsg',
    phoneCallCenter='phoneCallCenter',
    emailCallCenter='emailCallCenter',
    isAllowRegistWeb='isAllowRegistWeb',
    isAllowRegistIos='isAllowRegistIos',
    isAllowRegistAndroid='isAllowRegistAndroid',
    isMaintenance='isMaintenance',
    midtransUri="midtransUri",
    midtransKey="midtransKey",
    midtransCoreUri="midtransCoreUri",
    midtransCoreEnv="midtransCoreEnv",
    midtransCoreKey="midtransCoreKey",
    vapidKey="vapidKey",
    basicAuthKey="basicAuthKey",
    hmacSecretKey="hmacSecretKey",
    passwordSecretKey="passwordSecretKey",
    iDefault='iDefault',
    iFcmTry='iFcmTry',
    adminUri="adminUri",
    notifierUri='notifierUri',
    allowUri='allowUri',
    webStoreLink='webStoreLink',
    appStoreLink='appStoreLink',
    playStoreLink='playStoreLink',
    appWebStoreLink='appWebStoreLink',
    playWebStoreLink='playWebStoreLink',
    appStorePackage='appStorePackage',
    playStorePackage='playStorePackage',
    beneficiaryCode='beneficiaryCode',
    isShowTPAHotlineFeat='isShowTPAHotlineFeat',
    OTPRegExp='OTPRegExp'


}
