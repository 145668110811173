import { Injectable } from '@angular/core';
import { AlertController, LoadingController, Platform, ToastController } from '@ionic/angular';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { LangService } from '../lang/lang.service';
import * as moment from 'moment';
import 'moment/min/locales.min';
import { v4 as uuidv4 } from 'uuid';
import { Storage } from '@ionic/storage';
import { LocalNotifications } from '@awesome-cordova-plugins/local-notifications/ngx';

@Injectable({
  providedIn: 'root',
})
export class CommonUtillService {
  isToastVisible: boolean;
  uuid: string;

  constructor(
    private platform: Platform,
    private langSvc: LangService,
    private alertCtrl: AlertController,
    private loaderCtrl: LoadingController,
    private device: Device,
    private appVer: AppVersion,
    private toast: ToastController,
    private storage: Storage,
    private localNotifications: LocalNotifications
  ) {
    if (!this.isMobile()) {
      this.getWebId().then(id => {
        if (!id) {
          this.uuid = uuidv4();
          this.saveWebId(this.uuid);
        } else {
          this.uuid = id;
        }
      });
    }
  }
  private myToast: any;

  formatString(str: string, ...val: string[]) {
    for (let index = 0; index < val.length; index++) {
      str = str.replace(`{${index}}`, encodeURIComponent(val[index]));
    }
    return str;
  }

  formatMsg(str: string, ...val: string[]) {
    for (let index = 0; index < val.length; index++) {
      str = str.replace(`{${index}}`, val[index]);
    }
    return str;
  }

  showAlert(
    title: string,
    msg: string,
    additionalParams?: {
      params?: string[];
      callback?: Function;
      backdropDismiss?: boolean;
      cssClass?: string;
      textBtn?: string;
    }
  ): Promise<Boolean> {
    return new Promise(resolve => {
      const keys: string[] = [title, msg, 'trxCommonInput.btnClose'];
      if (additionalParams?.textBtn) {
        keys.push(additionalParams?.textBtn);
      }
      this.langSvc.getWords(keys).subscribe(words => {
        this.alertCtrl
          .create({
            header: words[title],
            message: additionalParams?.params ? this.formatMsg(words[msg], ...additionalParams.params) : words[msg],
            backdropDismiss: additionalParams?.backdropDismiss,
            cssClass: additionalParams?.cssClass ? additionalParams?.cssClass : '',
            buttons: [
              {
                text: additionalParams?.textBtn ? words[additionalParams.textBtn] : words['trxCommonInput.btnClose'],
                handler: () => {
                  if (typeof additionalParams?.callback !== 'undefined') {
                    additionalParams.callback();
                  }
                  resolve(true);
                },
              },
            ],
          })
          .then(alert => {
            alert.present();
          });
      });
    });
  }

  showDialog(
    title: string,
    msg: string,
    additionalParams?: {
      params?: string[];
      callback?: Function;
      cssClass?: string;
      txtBtnCancel?: string;
      txtBtnAgree?: string;
    }
  ): Promise<Boolean> {
    return new Promise(resolve => {
      const keys: string[] = [title, msg, 'trxCommonInput.btnNext', 'trxCommonInput.btnCancel'];
      if (additionalParams?.txtBtnCancel) {
        keys.push(additionalParams?.txtBtnCancel);
      }
      if (additionalParams?.txtBtnAgree) {
        keys.push(additionalParams?.txtBtnAgree);
      }
      this.langSvc.getWords(keys).subscribe(words => {
        this.alertCtrl
          .create({
            header: words[title],
            message: additionalParams?.params ? this.formatMsg(words[msg], ...additionalParams.params) : words[msg],
            cssClass: additionalParams?.cssClass ? additionalParams?.cssClass : '',
            buttons: [
              {
                text: additionalParams?.txtBtnCancel
                  ? words[additionalParams?.txtBtnCancel]
                  : words['trxCommonInput.btnCancel'],
                role: 'cancel',
                handler: () => {
                  resolve(false);
                },
              },
              {
                text: additionalParams?.txtBtnAgree
                  ? words[additionalParams?.txtBtnAgree]
                  : words['trxCommonInput.btnNext'],
                handler: () => {
                  if (typeof additionalParams?.callback !== 'undefined') {
                    additionalParams?.callback();
                  }
                  resolve(true);
                },
              },
            ],
          })
          .then(alert => {
            alert.present();
          });
      });
    });
  }

  showToast(header, message, color?, additionalParams?: { params?: string[] }) {
    this.langSvc.getWords([header, message, 'trxHOMELANDING.hide']).subscribe(words => {
      this.myToast = this.toast
        .create({
          header: words[header],
          message: additionalParams?.params
            ? this.formatMsg(words[message], ...additionalParams.params)
            : words[message],
          position: 'top',
          duration: 6000,
          color: typeof color !== 'undefined' ? color : 'dark',
          buttons: [
            {
              icon: 'close',
              role: 'close',
              handler: () => {
                this.isToastVisible = false;
              },
            },
          ],

           // buttons: [
          //   {
          //     text: words["trxHOMELANDING.hide"],
          //     role: 'Hide',
          //     handler: () => {
          //       console.log('Cancel clicked');
          //       this.isToastVisible = false;
          //     },
          //   },
          // ],

        })
        .then(toastData => {
          this.HideToast();
          toastData.onDidDismiss().then(() => {
            this.isToastVisible = false;
          });

          toastData.present().then(() => {
            this.isToastVisible = true;
          });
        });
    });
  }

  HideToast() {
    if (this.isToastVisible) {
      this.myToast = this.toast.dismiss();
    }
  }
  createLoader(): Promise<HTMLIonLoadingElement> {
    return new Promise(resolve => {
      this.langSvc.getWord('trxHOME.loading').subscribe(msg => {
        resolve(
          this.loaderCtrl.create({
            message: msg,
            //  spinner: null,
            //  cssClass: 'custom-class custom-loading',
          })
        );
      });
    });
  }

  isMobile() {
    if (this.platform.is('mobile') && !this.platform.is('mobileweb')) {
      return true;
    } else {
      return false;
    }
  }

  isDesktop() {
    if (this.platform.is('desktop')) {
      return true;
    } else {
      return false;
    }
  }

  getDeviceUUID(): string {
    if (this.isMobile()) {
      return this.device.uuid;
    } else {
      if (!this.uuid) {
        this.uuid = uuidv4();
        this.saveWebId(this.uuid);
      }
      return this.uuid;
    }
  }

  getAndroidSDKVer(): number {
    if (this.isAndroid()) {
      const ver = this.device.sdkVersion;
      if (ver) {
        return parseInt(ver);
      }
    }
    return 0;
  }

  saveWebId(firebaseXid: string) {
    this.storage.set('WebId', firebaseXid);
  }
  getWebId(): Promise<string> {
    return this.storage.get('WebId');
  }

  async getAppVerNum() {
    if (this.isMobile()) {
      const ver = await this.appVer.getVersionNumber();
      return ver;
    } else {
      return '0.0.1';
    }
  }

  isIos() {
    if (this.platform.is('ios') && !this.platform.is('mobileweb')) {
      return true;
    } else {
      return false;
    }
  }

  isAndroid() {
    if (this.platform.is('android') && !this.platform.is('mobileweb')) {
      return true;
    } else {
      return false;
    }
  }

  getDevice() {
    if (this.isAndroid()) {
      return 'ANDROID';
    }
    if (this.isIos()) {
      return 'IOS';
    }

    return 'WEB';
  }

  toRp(num: number, obfNum?: boolean, minFractionDigits?: number) {
    const formatter = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: minFractionDigits || 0,
      maximumFractionDigits: 2,
    });
    let val = formatter.format(num);
    if (typeof obfNum !== 'undefined') {
      if (obfNum) {
        val = val.replace('Rp', '').trim();
        if (val.includes('-')) {
          val = '(' + val.replace('-', '').trim() + ')';
        }
      }
    }
    return val;
  }

  toUSD(num: number, obfNum?: boolean, minFractionDigits?: number) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: minFractionDigits || 0,
      maximumFractionDigits: 2,
    });
    let val = formatter.format(num);
    if (typeof obfNum !== 'undefined') {
      if (obfNum) {
        val = val.replace('$', '').trim();
        if (val.includes('-')) {
          val = '(' + val.replace('-', '').trim() + ')';
        }
      }
    }
    return val;
  }

  toCurrency(num: number, currency: string, obfNum?: boolean, minFractionDigits?: number) {
    if (currency == 'RP.') {
      return this.toRp(num, obfNum, minFractionDigits);
    } else {
      return this.toUSD(num, obfNum, minFractionDigits);
    }
  }

  maskEmail(email: string) {
    return email.replace(/(.{3})(.*)(?=@)/, function (gp1, gp2, gp3) {
      for (let i = 0; i < gp3.length; i++) {
        gp2 += '*';
      }
      return gp2;
    });
  }

  maskPhoneNum(phoneNum: string) {
    return phoneNum.replace(/([$&+,:;=?@#|'<>.^*()%!-]|\w)(?=([0-9]{3}))/g, '*');
  }

  formatDate(date: string, formater: string): string {
    const curLang = moment(date, 'DD/MM/YYYY').locale(this.langSvc.getAngularLang());
    return curLang.format(formater).toString();
  }

  showProgressNotification(value: number, title: string, text: string) {
    if (this.isMobile()) {
      this.langSvc.getWords([title, text]).subscribe(words => {
        this.localNotifications.schedule({
          id: 1,
          title: words[title],
          text: words[text],
          sound: null,
          foreground: true,
          progressBar: { value: value, enabled: true },
        });
      });
    }
  }

  deleteNotification(id: number) {
    if (this.isMobile()) {
      this.localNotifications.cancel(id).then(
        isCancel => {
          console.log('rejected', isCancel);
        },
        rejected => {
          console.log('rejected', rejected);
        }
      );
    }
  }

  showNotification(title: string, text: string, callback?) {
    if (this.isMobile()) {
      this.langSvc.getWords([title, text]).subscribe(words => {
        this.localNotifications.clearAll().then(() => {
          this.localNotifications.schedule({
            id: 2,
            title: words[title],
            text: words[text],
            foreground: true,
          });

          if (typeof callback !== 'undefined') {
            this.localNotifications.on('click').subscribe(notification => {
              // Insert your logic here
              callback();
            });
          }
        });
      });
    }
  }

  blobToBase64(blob: Blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  blobToFile = (theBlob: Blob, fileName: string): File => {
    const dateTime = moment().format('MMDDYYYY_hhmmss');
    const b: any = theBlob;
    b.lastModifiedDate = new Date();
    b.name = dateTime + '_' + fileName;

    return <File>theBlob;
  }

  calculateAge(dateOfBirth: Date): number {
    const currentDate = new Date();
    const birthYear = dateOfBirth.getFullYear();
    const birthMonth = dateOfBirth.getMonth();
    const birthDay = dateOfBirth.getDate();

    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    let age = currentYear - birthYear;

    if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
      age--;
    }

    // console.log(`age: ${age} \ndob: ${dateOfBirth} \ncurr date: ${new Date()} \nDate Compare: ${moment().diff(dateOfBirth, 'year', true)}`);

    return age;
  }

}
