import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckPermissionDirective } from 'src/app/directives/check-permission/check-permission.directive';
import { CheckRoleDirective } from 'src/app/directives/check-role/check-role.directive';
import { ThousandSeparatorDirective } from 'src/app/directives/thousand-separator/thousand-separator.directive';



@NgModule({
  declarations: [CheckRoleDirective,CheckPermissionDirective, ThousandSeparatorDirective],
  imports: [
    CommonModule
  ],
  exports: [
    CheckRoleDirective,
    CheckPermissionDirective,
    ThousandSeparatorDirective,
  ]
})
export class SharedDirectiveModule { }
