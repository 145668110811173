export const environment = {
  production: false,
  remoteVer: '0.0.1',
  remoteConfig: new Map<string, any>(),
  firebaseVer:"app_version",
  firebaseEnv:"app_environment_01",
  firebaseConfig: {
    apiKey: 'AIzaSyB4don8Bv77rzzx_Lbiby6Dr2MTnaZydeA',
    authDomain: 'connect-paninlife.firebaseapp.com',
    projectId: 'connect-paninlife',
    storageBucket: 'connect-paninlife.appspot.com',
    messagingSenderId: '715994264432',
    appId: '1:715994264432:web:c65d171bb9238fbf7495b5',
    measurementId: 'G-TJV3B5G268',
  },
  host: "https://connect-alt.panindai-ichilife.co.id"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
